import * as React from "react";
import { useState } from "react";
import { Link } from "gatsby";
import TemplatePage1 from "@components/TemplatePage/TemplatePage";
import styled from "styled-components";
import upch from "@images/upch.png";
import iecs from "@images/iecs.png";
import diagrama from "@images/organigrama.png";

const IndexPage = () => {

  const changingContentArray = [
    <p>
      <b>Comité Directivo:</b> <br/> Encargados de proporcionar dirección estratégica para el logro de los objetivos de la Red en LAC. De esta manera, dirigirán las acciones de fortalecimiento, capacitación de la Red LAC y proporcionarán orientación sobre investigación en calidad de salud.
      <br />
      Además, organizarán las prioridades del programa y áreas de trabajo, unirán esfuerzos con otras redes de calidad en salud global, programas de investigación, innovación y capacitación en Perú, Argentina y otros países de LAC.
      <br/>
      
    </p>,
    <p>
      <b>Comité Asesor Técnico (CAT):</b> <br/> Encargados de asesorar en líneas de investigación y actividades para el fortalecimiento de la Red. Además, asesorará a la Red en otros aspectos que generen capacidad y mayores recursos.
      <br/>
      <Link to={"/acerca/estructura/cat"}>Ver más</Link>
    </p>,
    <p>
      <b>Grupo estratégico de políticas regionales (GEPR):</b>  <br/> será un órgano integrado por lideres regionales y miembros de organismos internacionales, cuyo objetivo será facilitar y guiar la traducción de políticas y la difusión de los nuevos hallazgos de la investigación, así como la búsqueda de oportunidades de financiamiento.
      <br/>
      <Link to={"/acerca/estructura/gepr"}>Ver más</Link>
    </p>,
    <p>
      <b>Centros Core:</b> <br/> Son los Centros de investigación fundadores de la Red e integrantes de la Red QuEST Global, además corresponden a las instituciones de los Investigadores Principales. Estos son el Instituto de Efectividad Clínica y Sanitaria (IECS) y la Universidad Peruana Cayetano Heredia (UPCH) con el apoyo con la Facultad de Salud Pública y Administración.<br/>
      <ImageContainer>
        <img src={upch} alt={upch} />
        <img src={iecs} alt={iecs} />
      </ImageContainer>
      <br/>
      <Link to={"/acerca/estructura/centroscore"}>Ver más</Link>
    </p>,
    <p>
      <b>Centros Afiliados:</b> <br/> Son grupos de investigación o instituciones que trabajan en la región de LAC en el área de calidad en salud. Los afiliados también participan en las actividades del QuEST LAC como investigación, seminarios web y desarrollo de habilidades. Además, contribuirán a debates y foros, y serán enlaces de políticas con gobiernos, líderes nacionales y socios.
      <br/>

    </p>
  ]

  const [content, setcontent] = useState(-1)

  return (
    <TemplatePage1
      title={"QuEST LAC: Estructura"}
      showtitle={false}
      content={(
        <Wrapper >
          <Content>
            <MapImageContainer>
              
              <img src={diagrama} alt={diagrama} />
        
            </MapImageContainer>
            <MapZone
              width={"51%"}
              height={"20%"}
              left={"28%"}
              top={"4%"}
              onClick={()=>{setcontent(0)}}
            />
            <MapZone
              width={"27%"}
              height={"11%"}
              left={"15%"}
              top={"29%"}
              onClick={()=>{setcontent(1)}}
            />
            <MapZone
              width={"27%"}
              height={"11%"}
              left={"64%"}
              top={"29%"}
              onClick={()=>{setcontent(2)}}
            />
            <MapZone
              width={"57%"}
              height={"15%"}
              left={"25%"}
              top={"45%"}
              onClick={()=>{setcontent(3)}}
            />
            <MapZone
              width={"98%"}
              height={"30%"}
              left={"2%"}
              top={"66%"}
              onClick={()=>{setcontent(4)}}
            />
            {
              content>-1
              ?(
                <ChangingZone>
                {changingContentArray[content]}
                <span onClick={()=>{setcontent(-1)}}>
                Cerrar
                </span>
            </ChangingZone>
              )
              :null
            }
          </Content> 
          <TextPart>
            <h1>Estructura</h1>
            Para conocer más sobre cada uno, haga clic sobre los diferentes cuadros:
          </TextPart>
        </Wrapper >
      )}
    />
  )
};

export default IndexPage;

const TextPart = styled.h1`
width: 60%;
min-width: 360px;
text-align: center;
padding: 5px 10px;

h1{
  color: var(--tema4);
  font-size:2.5rem;
  text-align:right;
  font-weight: bold;
}

@media only screen and (min-width:768px){
  width: auto;
  min-width: auto;
  text-align:right;
  max-width: 200px;
}
`;

const ChangingZone = styled.div`
position: absolute;
width:100%;
background-color: #000000DD;
height:100%;
top:0;
left:0;
padding: 20px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
text-align: center;

a{
  display: inline-block;
  cursor: pointer;
  padding: 3px 5px;
  color: white;
  margin: 10px auto;
  width: 180px;
  background-color: var(--color2);
}

@media only screen and (min-width:1024px){
  font-size: 14px;
line-height: 28px;
}

font-size: 12px;
line-height: 18px;

color: white;

p{
  max-width: 360px;
}

b{
font-weight: bold;
}

img{
  margin: 10px auto;
  max-width: 230px;
}

span{
  display: inline-block;
  cursor: pointer;
  padding:5px;
  color: white;
  background-color: var(--tema4);
}

a{
  display: inline-block;
  cursor: pointer;
  padding: 3px 5px;
  color: white;
  margin: 10px auto;
  width: 180px;
  background-color: var(--color2);
}

animation: appear 1s;

@keyframes appear{
0%{
  opacity: 0;
  transform: scale(0);
}
100%{
  opacity: 1;
  transform: scale(1);
}

}

`;

const Wrapper = styled.div`
text-align: justify;
  padding-top: 1rem;
  display:  flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;

  @media only screen and (min-width: 1024px){
    flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  }

`;

const MapZone = styled.div`
  //background-color: red;
  position: absolute;
  width: ${props=>props.width?props.width:0};
  height:  ${props=>props.height?props.height:0};
  left: ${props=>props.left?props.left:0};
  top:  ${props=>props.top?props.top:0};
`;

const Content = styled.div`
position: relative;
padding-top: 1rem;
padding-bottom: 1rem;
display: flex;
flex-direction: column;
align-items: center;
width: 90%;
min-width: 360px;
`;


const MapImageContainer = styled.div`
min-width: 360px;
max-width: 100%;
display:flex;
flex-direction: column;
text-align: center;
img{
    height: auto;
    width: 100%;
    margin: auto;
}
span{
    font-size: 0.7rem;
    line-height: 0.8rem;
    margin-bottom: 0.5rem;
    text-align: center;
  }

`;

const ImageContainer = styled.div`
width:75%;
margin: auto;
display: flex;

flex-direction: column;

  img{
    width: 90%;
  }


`;




